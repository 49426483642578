@import "src/App.scss";
section.edit-profile{
    div.change-pass{
        form{
            border-radius: 1.5rem;
        }
        button{
            background-color:#4c7d78;
        }
        .user-icon, .pass-icon{
            right: 15px;
            top: 40px;
            svg{
                width: 20px;
                height: 20px;
                path{
                    fill: #4c7d78;
                }
            }
        }
        .pass-btn{
            background-color: transparent;
            left: 15px;
            top: 40px;
            svg{
                width: 20px;
                height: 20px;
                path{
                    fill: #4c7d78;
                }
            }
        }
        .form-control:focus {
            color: $secondary-color !important;
            border-color: $secondary-color !important;
            box-shadow: 0 0 0 0.25rem rgba(76,125,120,.25) !important;
        }
        .inputErr.form-control:focus {
            color: red !important;
            border-color: red !important;
            box-shadow: 0 0 0 0.25rem rgba(220,53,69,.25) !important;
        }
        ::placeholder {
            color: $secondary-color;
        }
    
        ::-webkit-calendar-picker-indicator {
            filter: invert(1);
        }
    }
}