// SASS Variable =========================
//#433e2b

$white-color: white;
$logo-gold-color: #a29459;
$logo-blue-color: #4c7d78;

$primary-color: #dfd093;  //#dbc879;
$secondary-color: $logo-blue-color;
$tertiary-color: $logo-gold-color;
$quaternary-color:#433e2b;

$primary-bg: #c1b379; //$logo-gold-color;
$secondary-bg: $logo-blue-color;
$tertiary-bg: #f7f4dc;

$btn-primary-bg:$logo-blue-color;
$btn-secondary-bg:$logo-gold-color;

@font-face {
    font-family: 'TheSans';
    src: url('./assets/fonts/TheSans\ Plain.ttf');
}

*{
    box-sizing: border-box;
    scroll-behavior: smooth;
}

// ::selection{
    //     background-color: #86b7fe;
//     color: $white-color;
// }

:root {
    --PhoneInput-color--focus: #03b2cb;
	--PhoneInputInternationalIconPhone-opacity: 0.8;
	--PhoneInputInternationalIconGlobe-opacity: 0.65;
	--PhoneInputCountrySelect-marginRight: 0.35em;
	--PhoneInputCountrySelectArrow-width: 0.3em;
	--PhoneInputCountrySelectArrow-marginLeft: var(--PhoneInputCountrySelect-marginRight);
	--PhoneInputCountrySelectArrow-borderWidth: 1px;
	--PhoneInputCountrySelectArrow-opacity: 0.45;
	--PhoneInputCountrySelectArrow-color: currentColor;
	--PhoneInputCountrySelectArrow-color--focus: var(--PhoneInput-color--focus);
	--PhoneInputCountrySelectArrow-transform: rotate(45deg);
	--PhoneInputCountryFlag-aspectRatio: 1.5;
	--PhoneInputCountryFlag-height: 1em;
	--PhoneInputCountryFlag-borderWidth: 1px;
	--PhoneInputCountryFlag-borderColor: rgba(0,0,0,0.5);
	--PhoneInputCountryFlag-borderColor--focus: var(--PhoneInput-color--focus);
	--PhoneInputCountryFlag-backgroundColor--loading: rgba(0,0,0,0.1);
}

// Body =======================================
body{
    padding: 0;
    margin: 0;
    position: relative;
    font-family: "TheSans";//"El Messiri", sans-serif;
    font-optical-sizing: auto;
    font-style: normal;
    overflow-x: hidden;
    svg path{
        fill: $white-color;
    }
    // background-color: #3a7ab5;
}
.modal-open{
    padding-right:0px !important;
}
.bg-transparent{
    background-color: transparent;
}
// .bg-overlay{
//     background-color: rgb(234 231 230 / 30%);
//     width: 100%;
//     position: absolute;
//     height: 100%;
//     z-index: 2;
// }

.secondary-color{
    color: $secondary-color !important;
}
.button-bg-color{
    background-color: $secondary-color;
    color: $primary-color;
}
.button-bg-color:hover{
    background-color: $primary-color;
    color: $secondary-color;
}

.row {
    margin-left: 15px;
    margin-right: 15px;
}

.btn-check:focus+.btn,.btn:focus {
    outline: 0;
    box-shadow: none !important;
}
.btn:hover {
    color: $secondary-color;
}

.modal-header .btn-close {
    margin: -.5rem auto -.5rem 0 !important;
}
.modalContent{
    border-radius: 1rem;
    background-image: url("../src/assets/images/intro.webp");
    background-repeat: no-repeat;
    background-position: 100% 100%;
    background-size: cover;
    background-attachment: scroll;
}
.modalBtn{
    background-color:#4c7d78 !important;
}
.modal-header {
    border-bottom: none;
}



