@import "src/App.scss";

section.courses{
    .course-title{
        padding: 100px 0;
        span{
            position: relative;
            z-index: 1;
        }
        .course-star{
            top: -80px;
        }
        }
    .course-content{
    .content-txt{
        background-color: $secondary-bg;
        color: $primary-color;
        height: 400px;
    }
    }
    .course-txt{
        .course-bg-1,.course-bg-2,.course-bg-3{
          height: 200px;
          padding: 50px 20px;
        }
        .course-bg-1{
          background-color: #c1b379;
        }
        .course-bg-2{
          background-color: #cdc28d;
        }
        .course-bg-3{
          background-color: #c1b379;
        }
    }
    .course-pdf{
        .prog-pdf-titel{
            color: $secondary-color;
        }
    }
}