@import "src/App.scss";

section.ContactSection{
    padding: 50px 0;
    .contact-title{
      padding: 50px 0 100px 0;
      margin: -1px 0;
      span{
        position: relative;
        z-index: 1;
      }
      .contact-star{
        top: -80px;
      }
    }
    .contact-map{
        position: relative;
        iframe{
            margin-bottom: -9px;
        }
      }
    .contact-txt{
        
        .contact-bg-1,.contact-bg-2,.contact-bg-3{
          height: 250px;
          padding: 50px 20px;
        }
        .contact-bg-1{
          background-color: #e6c36a;//#c1b379;
        }
        .contact-bg-2{
          background-color: #cdc28d;
        }
        .contact-bg-3{
          background-color: $logo-gold-color;
        }
        .txt-color{
          color: $quaternary-color;
        }
    }
  }