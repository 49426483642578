
div.modalContent{
    span.user-icon{
        right: 15px;
        top: 40px;
        svg{
            width: 20px;
            height: 20px;
            path{
                fill: #4c7d78;
            }
        }
    }
} 
