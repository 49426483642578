@import "src/App.scss";

// Home Header =======================================
header.home-header{
  img{
    height: 100vh;
    width: 100%;
  }
  .carousel-control-next{
    justify-content: start;
    padding-right: 10px;
  }
  .carousel-control-prev{
    justify-content: end;
    padding-left: 10px;
  }
  .carousel-control-next-icon, .carousel-control-prev-icon{
    width: 2.5rem;
    height: 2.5rem;
  }
  .carousel-indicators [data-bs-target]{
    height:8px;
    width: 40px;
    border: 10px solid transparent;
    border-radius: 15px;
    // background-color:$secondary-bg;
}
}
// Home About =======================================
section.home-about{
  padding: 50px 0 100px 0;
  .about-title{
    // background-color: #f6edde;
    padding: 50px 0;
    span{
      position: relative;
      z-index: 1;
    }
    .about-star{
      top: -80px;
    }
  }
  .about-txt{
    color: $quaternary-color;
  }
  .img-div div{
    text-align: center;
    color: $primary-color;
    font-weight: bold;
    padding-top: 10%;
    padding-bottom: 10%;
    bottom: -20%;
    right: 0;
    background-color: $secondary-bg;
  }
}

// Home Programme ====================================
  section.home-programme{
    padding: 50px 0;
    .programme-title{
      padding: 50px 0 100px 0;
      span{
        position: relative;
        z-index: 1;
      }
      .programme-star{
        top: -80px;
      }
    }
    .programme-img{
      padding: 100px 0 50px 0;
      background-image: url("../../assets/images/home-programme1.jpg");
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      background-attachment: scroll;
    }
    .programme-border{
      top:-25%;
    }
    .programme-txt{
      color: #f7f4dc;
    }
  }

// Home Programmes ===================================
section.home-programmes{
  padding: 50px 0 0 0;
  .programmes-title{
    padding: 50px 0 100px 0;
    span{
      position: relative;
      z-index: 1;
    }
    .programmes-star{
      top: -80px;
    }
  }
  .prog-title{
    min-height: 80px;
  }
  .prog-details{
    min-height: 75px;
    color: $quaternary-color;
  }
}
// Home Courses ======================================
  section.home-courses{
    padding: 50px 0;
    .courses-title{
      padding: 50px 0 100px 0;
      margin: -1px 0;
      span{
        position: relative;
        z-index: 1;
      }
      .courses-star{
        top: -80px;
      }
    }
    .courses-img{
      padding: 100px 0 0 0;
      background-image: url("../../assets/images/home-programmes1.jpg");
      background-attachment: fixed;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }
    .courses-border{
      top:-25%;
    }
  }

// Home Pray ======================================
  section.home-pray{
    padding: 50px 0;
    .pray-title{
      padding: 50px 0 100px 0;
      span{
        position: relative;
        z-index: 1;
      }
      .pray-star{
        top: -80px;
      }
    }
    .pray-img{
      background-image: url("../../assets/images/home-pray.png");
      background-position: center;
      background-repeat: no-repeat;
      background-attachment: scroll;
      background-size: 100% 100%;
      padding-top: 500px;
    }
    .pray-img,.pray-txt{
      height: 100%;
    }
    .pray-txt{
      padding: 50px 0 50px 0;
      background-color: #e6c36a;
      color: #4c7d78;
      .pray-star{
        top: 5%;
      }
    }
  }
  

// Media ======================================

  
  @media only screen and (max-width: 1200px) { 
    section.home-programmes{
      .prog-details{
        min-height: 90px;
      }
    }
  }
  
  @media only screen and (max-width: 992px) { 
    section.home-programmes{
      .prog-title{
        min-height: auto;
      }
      .prog-details{
        min-height: auto;
      }
    }
    section.home-courses{
      .courses-img{
        background-attachment: scroll !important;
      }
    }
  }

  @media only screen and (max-width: 600px) { 
    section.home-about{
      .img-div div{
        font-size: 1rem !important;
      }
    }
  }
