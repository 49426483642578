@import "src/App.scss";

section.register{
    form{
        border-radius: 1.5rem;
    }
    button{
        background-color:#4c7d78;
    }
    .user-icon, .pass-icon{
        right: 15px;
        top: 40px;
        svg{
            width: 20px;
            height: 20px;
            path{
                fill: #4c7d78;
            }
        }
    }
    .pass-btn{
        background-color: transparent;
        left: 15px;
        top: 40px;
        svg{
            width: 20px;
            height: 20px;
            path{
                fill: #4c7d78;
            }
        }
    }
    .form-control:focus {
        color: $secondary-color !important;
        border-color: $secondary-color !important;
        box-shadow: 0 0 0 0.25rem rgba(76,125,120,.25) !important;
    }
    .inputErr.form-control:focus {
        color: red !important;
        border-color: red !important;
        box-shadow: 0 0 0 0.25rem rgba(220,53,69,.25) !important;
    }
    ::placeholder {
        color: $secondary-color;
    }

    ::-webkit-calendar-picker-indicator {
        filter: invert(1);
    }



    
    .PhoneInputInternationalIconPhone{
        fill: #4c7d78;  
    }
    .PhoneInputInternationalIconGlobe path{
        fill:transparent !important;
    }

    .bgcolor{
        background-color: $tertiary-bg;
    }
    .PhoneInput {
        /* This is done to stretch the contents of this component. */
        display: flex;
        align-items: center;
    }

    .PhoneInputInput::placeholder{
        text-align: right;
    }
    .PhoneInputInput {
        // direction: rtl !important;
        /* The phone number input stretches to fill all empty space */
        flex: 1;
        /* The phone number input should shrink
            to make room for the extension input */
        min-width: 0;
        display: block;
        width: 100%;
        padding: 0.375rem 0.75rem;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        color:  $secondary-color ;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #ced4da;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        border-radius: 0.25rem;
        transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    }
    .PhoneInputInput:focus{
        background-color: #fff;
        outline: 0;
        color: $secondary-color !important;
        border-color: $secondary-color !important;
        box-shadow: 0 0 0 0.25rem rgba(76,125,120,.25) !important;
    }

    .PhoneInputCountryIcon {
        width: calc(var(--PhoneInputCountryFlag-height) * var(--PhoneInputCountryFlag-aspectRatio));
        height: var(--PhoneInputCountryFlag-height);
    }

    .PhoneInputCountryIcon--square {
        width: var(--PhoneInputCountryFlag-height);
    }

    .PhoneInputCountryIcon--border {
        /* Removed `background-color` because when an `<img/>` was still loading
            it would show a dark gray rectangle. */
        /* For some reason the `<img/>` is not stretched to 100% width and height
            and sometime there can be seen white pixels of the background at top and bottom. */
        background-color: var(--PhoneInputCountryFlag-backgroundColor--loading);
        /* Border is added via `box-shadow` because `border` interferes with `width`/`height`. */
        /* For some reason the `<img/>` is not stretched to 100% width and height
            and sometime there can be seen white pixels of the background at top and bottom,
            so an additional "inset" border is added. */
        box-shadow: 0 0 0 var(--PhoneInputCountryFlag-borderWidth) var(--PhoneInputCountryFlag-borderColor),
            inset 0 0 0 var(--PhoneInputCountryFlag-borderWidth) var(--PhoneInputCountryFlag-borderColor);
    }

    .PhoneInputCountryIconImg {
        /* Fixes weird vertical space above the flag icon. */
        /* https://gitlab.com/catamphetamine/react-phone-number-input/-/issues/7#note_348586559 */
        display: block;
        /* 3rd party <SVG/> flag icons won't stretch if they have `width` and `height`.
            Also, if an <SVG/> icon's aspect ratio was different, it wouldn't fit too. */
        width: 100%;
        height: 100%;
    }

    .PhoneInputInternationalIconPhone {
        opacity: var(--PhoneInputInternationalIconPhone-opacity);
    }

    .PhoneInputInternationalIconGlobe {
        opacity: var(--PhoneInputInternationalIconGlobe-opacity);
    }

    /* Styling native country `<select/>`. */

    .PhoneInputCountry {
        position: relative;
        align-self: stretch;
        display: flex;
        align-items: center;
        margin-right: var(--PhoneInputCountrySelect-marginRight);
    }

    .PhoneInputCountrySelect {
        position: absolute;
        margin-top: 13px;
        height: auto;
        width: auto;
        z-index: 1;
        border: 0;
        opacity: 0;
        cursor: pointer;
    }

    .PhoneInputCountrySelect[disabled],
    .PhoneInputCountrySelect[readonly] {
        cursor: default;
    }

    .PhoneInputCountrySelectArrow {
        margin:5px;
        display: block;
        content: '';
        width: var(--PhoneInputCountrySelectArrow-width);
        height: var(--PhoneInputCountrySelectArrow-width);
        margin-left: var(--PhoneInputCountrySelectArrow-marginLeft);
        border-style: solid;
        border-color:  $secondary-color !important;
        border-top-width: 0;
        border-bottom-width: var(--PhoneInputCountrySelectArrow-borderWidth);
        border-left-width: 0;
        border-right-width: var(--PhoneInputCountrySelectArrow-borderWidth);
        transform: var(--PhoneInputCountrySelectArrow-transform);
        opacity: var(--PhoneInputCountrySelectArrow-opacity);
    }

    .PhoneInputCountrySelect:focus + .PhoneInputCountryIcon + .PhoneInputCountrySelectArrow {
        opacity: 1;
        color: $secondary-color !important;
    }

    .PhoneInputCountrySelect:focus + .PhoneInputCountryIcon--border {
        box-shadow: 0 0 0 0.25rem rgba(76,125,120,.25) !important;
    }

    .PhoneInputCountrySelect:focus + .PhoneInputCountryIcon .PhoneInputInternationalIconGlobe {
        opacity: 1;
        color: $secondary-color !important;
    }
}
