@import "src/App.scss";

section.programmes{
    .programmes-title{
        padding: 100px 0;
        span{
            position: relative;
            z-index: 1;
        }
        .programmes-star{
            top: -80px;
        }
        }
    .programmes-content{
    .content-txt{
        background-color: $secondary-bg;
        color: $primary-color;
        height: 400px;
    }
    }
    .programmes-txt{
        .programmes-bg-1,.programmes-bg-2,.programmes-bg-3{
          height: 200px;
          padding: 50px 20px;
        }
        .programmes-bg-1{
          background-color: #c1b379;
        }
        .programmes-bg-2{
          background-color: #cdc28d;
        }
        .programmes-bg-3{
          background-color: #c1b379;
        }
    }
    .programmes-pdf{
        .prog-pdf-titel{
            color: $secondary-color;
        }
    }

    .login-btn{
      text-align: right;
      background-color: $btn-primary-bg;
      color: $white-color;
      // white-space: nowrap;
      // height: 45px;
      padding: 0.5rem 1rem;
    }
    .login-btn:focus {
      box-shadow: none;
    }
    .login-btn:hover{
      background-color: $btn-secondary-bg;
    }
}