@import "src/App.scss";

section.contact{
    .cont-title{
        padding: 100px 0;
        span{
          position: relative;
          z-index: 1;
        }
        .cont-star{
          top: -80px;
        }
      }
}