@import "src/App.scss";
// Footer ======================================
section.footer{
    padding: 50px 0 0 0;
    .footer-social{
      svg{
        width: 30px;
        height: 30px;
        path{
          fill: $secondary-color !important;
        }
      }
    }
    .footer-copyright{
      color: $secondary-color;
    }
  }

  @media only screen and (max-width: 992px) {
    section.footer{
      .social{
        width: 75% !important;
      }
    }
  }