@import "src/App.scss";

section.about{
  .about-bg{
    padding: 0 0 100px 0;
  }
  .about-title{
    // background-color: #f6edde;
    padding: 100px 0 100px 0;
    span{
      position: relative;
      z-index: 1;
    }
    .about-star{
      top: -80px;
    }
  }

  .img-div div{
    text-align: center;
    color: $primary-color;
    font-weight: bold;
    padding-top: 10%;
    padding-bottom: 10%;
    bottom: -20%;
    right: 0;
    background-color: $secondary-bg;
  }
}