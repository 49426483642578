@import "src/App.scss";

section.header{
    .hero-img{
      img{
        height: 100vh;
      }
    }
    .hero-txt{
      background-color: $secondary-bg;
      color: $primary-color;
    }
    .header-social{
      svg{
        width: 30px;
        height: 30px;
      }
    }
  }

  
  @media only screen and (max-width: 1200px) { 
    section.header{
      .hero-txt span{
        font-size: 1.5rem !important;
      }
      .header-social{
        width: 75% !important;
      }
      .header-social{
        svg{
          width: 30px !important;
          height: 30px !important;
        }
      }
    }
  }

  @media only screen and (max-width: 992px) { 
    section.header{
      .hero-txt span:first-child{
        font-size: 1.5rem !important;
      }
      .header-social{
        width: 75% !important;
      }
      .header-social{
        svg{
          width: 25px !important;
          height: 25px !important;
        }
      }
    }
  }

  @media only screen and (max-width: 767px) {
    section.header{
      .hero-txt span:first-child{
        font-size: 1.2rem !important;
      }
      .header-social{
        width: 75% !important;
      }
      .header-social{
        svg{
          width: 20px !important;
          height: 20px !important;
        }
      }
    }
  }

  @media only screen and (max-width: 600px) { 
    section.header{
      .hero-txt span:first-child{
        font-size: 1rem !important;
      }
      .header-social{
        width: 100% !important;
      }
      .header-social{
        svg{
          width: 20px !important;
          height: 20px !important;
        }
      }
    }
  }
