@import "src/App.scss";
// NavBar ======================================
section.nav-bar{
  .navbar{
    padding: 0 !important;
  }
    .nav-logo{
      img{
        height: 100px;
      }
    }
    .navbar-light.fixed-top .navbar-collapse::after{
      content: "";
      position: absolute;
      width: 100%;
      height: 60px;
      top: -60px;
      left: 0;
      background-color: $white-color;
    }
    .navbar-light{
    background-color: transparent !important;
    }
    .navbar-light.chnageColor{
      background-color: $secondary-bg !important;
      .nav-logo{
        position: relative;
        img{
          position: absolute;
          top: -10px;
          height: 100px;
        }
      }
    }
    .navbar-light .navbar-nav{
      padding: 0 5%;
    }
    .navbar-light .navbar-nav .nav-link{
      color: $white-color;
    }
    .navbar-light .navbar-nav .nav-link:hover{
      color: $primary-color;
    }
    .navbar-light .navbar-nav .dropdown-menu {
      background-color: $secondary-color ;
      top: 70px;
      right: -40%;
      border-radius: 10px;
    }
    .navbar-light .navbar-nav .dropdown-menu::before {
      content: "";
      position: absolute;
      border-bottom: 8px solid $secondary-color;
      border-right: 8px solid transparent;
      border-top: 0;
      border-left: 8px solid transparent;
      top: -7px;
      right: 44%;
      margin: 0 auto;
      // border-color: $secondary-color ;
    }
    .navbar-light .navbar-nav .dropdown-menu .nav-link {
      color: $white-color;
    }
    .navbar-light .navbar-nav .dropdown-menu .nav-link:hover {
      background-color: #f7f4dc80;
    }
    .navbar-light .navbar-toggler{
      background-color: $secondary-bg;
    }
    .navbar-light .navbar-toggler-icon{
      width: 25px;
      height: 25px;
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='%23ffffff' stroke-linecap='round' stroke-miterlimit='10' stroke-width='3' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e")
    }
    .navbar-light .navbar-nav li.dropdown a::after{
      vertical-align: 0em;
    }
    .dropdown-right::after{
      margin-right: 0.255em !important;
    }
    ul.dropdown-menu{
      border: 0;
    }
    .login-btn{
      text-align: right;
      background-color: $btn-secondary-bg;
      color: $white-color;
      // white-space: nowrap;
      // height: 45px;
      padding: 0.5rem 1rem;
    }
    .login-btn:focus {
      box-shadow: none;
    }
  }
  
  @media only screen and  (min-width: 992px){
    .navbar-light.chnageColor{
      height: 70px;
    }
    .navbar-light .navbar-nav .nav-item{
      display: flex;
      align-items: center;
    }

  }

  @media only screen and  (max-width: 991px) { 
    section.nav-bar{
      .fixed-top{
        .nav-logo{
          display: none;
        }
      }
      .navbar-light .navbar-nav{
        padding-bottom: 10px;
      }
      .navbar-light .navbar-collapse{
        margin-top: 0px !important;
        background-color: $secondary-bg !important;
      }
      .navbar-nav li.nav-item {
        width: 100%;
        text-align: start;
      }
      // .navbar-nav li.nav-item .nav-link{
      //   padding-right: 30px;
      // }
      .navbar-nav li.nav-item ul{
        padding: 0 25px 0 0;
        margin: 0;
      }
      .navbar-light .navbar-toggler {
        color: transparent !important;
        border-color: transparent !important;
      }
      .login-btn{
        margin-right: 0;
        padding-right: 30px;
        margin-left: 0;
      }
    }
   }
